import { selectorFamily } from 'recoil'
import { SegmentsEnum } from '../helpers/Enums'
import { Capitalize } from '../helpers/Functions'
import * as AllAtoms from './atoms'
import moment from 'moment'

export const dynamicTableSelector = selectorFamily({
	key: 'dynamicTableSelector',
	get:
		(props) =>
		({ get }) => {
			const {collection, selectedRecord, selectedTab, pageInfo, keyword, selectedClient} = props; // prettier-ignore
			const atomName = SegmentsEnum[Capitalize(collection)]?.atom
			const searchAtom = SegmentsEnum[Capitalize(collection)]?.searchAtom
			const searchOrders = searchAtom && get(searchAtom)
			let allOrders = atomName && get(atomName)

			allOrders = searchOrders || allOrders
			let orders = []
			let collectionData = []
			let totalCount = 0
			if (Capitalize(collection) === 'Import') {
				orders = getImportOrders(allOrders)
			} else if (Capitalize(collection) === 'Export') {
				orders = getExportOrders(allOrders)
			} else if (Capitalize(collection) === 'LongHaul') {
				orders = getLongHaulOrders(allOrders)
			}
			orders = selectedClient.businessCode === 'All Clients' ? orders : orders.filter((order) => order.businessCode === selectedClient.businessCode)
			totalCount = orders?.length
			collectionData =
				keyword?.length > 0
					? orders?.sort((a, b) => b?.orderNumber - a?.orderNumber)
					: orders
							?.sort((a, b) => b?.orderNumber - a?.orderNumber)
							?.slice(pageInfo?.pageNo * pageInfo?.pageSize - pageInfo?.pageSize, pageInfo?.pageNo * pageInfo?.pageSize)
			if (selectedRecord) {
				const order = orders?.find((order) => order?._id === selectedRecord?._id)
				collectionData =
					order?.movements?.[selectedTab] &&
					Object.values(order?.movements?.[selectedTab])
						?.map((movement) => {
							return movement?.job?.matched === 'Matched' || movement?.job?.matched === 'PartialMatched'
								? movement
								: movement?.subOrders?.map((subOrder) => ({
										_id: movement?._id,
										job: movement.job,
										subOrders: [subOrder],
								  }))
						})
						.flat()
						?.sort((a, b) => parseInt(b?._id || 0) - parseInt(a?._id || 0))
			}

			return { collectionData, totalCount }
		},
})

export const corporatesSelector = selectorFamily({
	key: 'corporatesSelector',
	get:
		(props) =>
		({ get }) => {
			const { collection } = props
			const atomName = SegmentsEnum[Capitalize(collection)]?.atom
			const searchAtom = SegmentsEnum[Capitalize(collection)]?.searchAtom
			const searchOrders = searchAtom && get(searchAtom)
			let allOrders = atomName && get(atomName)
			allOrders = searchOrders || allOrders
			const obj = {}
			if (allOrders.length > 0) {
				allOrders.forEach((item) => {
					if (obj[item?.businessCode]) {
						obj[item?.businessCode] += 1
					} else {
						obj[item?.businessCode] = 1
					}
				})
			}

			const corporates = get(AllAtoms.corporatesAtom)

			const filteredCorporates = corporates?.filter((item) => obj.hasOwnProperty(item.businessCode))
			return filteredCorporates?.map((corporate) => ({
				_id: corporate?._id,
				businessCode: corporate?.businessCode,
				businessName: corporate?.businessName,
				count: obj[corporate.businessCode],
			}))
		},
})

export const authSelector = selectorFamily({
	key: 'authSelector',
	get:
		(props) =>
		({ get }) => {
			const user = get(AllAtoms.userAtom)
			// console.log({ user })
			const authorized = user?.UserType?.filter((type) => ['Admin', 'SuperAdmin'].includes(type?.userType))?.length >= 1
			// console.log({ authorized })
			return user?.UserType ? { user, authorized } : undefined
		},
})
/* ============================================================================================== */
/* ============================== HELPER FUNCTIONS (FOR SELECTORS) ============================== */
/* ============================================================================================== */
// export const getDoStatus = (groupedOrders) => {
// 	const currentDoStatuses = groupedOrders?.map((order) => order?.doInformation?.currentStatus?.status)
// 	if (currentDoStatuses?.some((status) => status === 'gatePass')) {
// 		return 'gatePass'
// 	} else if (currentDoStatuses?.some((status) => status === 'doIssued')) {
// 		return 'doIssued'
// 	}
// 	return ''
// }

const getImportOrders = (orders) => {
	// console.log('orders: ', orders)
	const parentOrders = orders?.map((order) => {
		const parentOrder = order?.ParentOrder?.ParentImport
		const orderMovements = order?.ParentOrder?.Movements
		const subImport = orderMovements?.[0]?.ChildOrder?.SubImport
		let movements = {}

		const subOrders =
			orderMovements &&
			Object.values(
				orderMovements?.reduce((subOrders, movement) => {
					subOrders[movement?.ChildOrder?.SubImport?._id] = movement?.ChildOrder?.SubImport
					return subOrders
				}, {})
			)

		const containerSpecifics = subOrders?.reduce((acc, subOrder) => {
			acc[subOrder?.dimension] = (acc[subOrder?.dimension] || 0) + 1
			return acc
		}, {})

		const totalWeight = subOrders?.reduce((acc, subOrder) => acc + parseFloat(subOrder?.freightWeight), 0)
		const totalNumberOfBales = subOrders?.reduce((acc, subOrder) => acc + parseFloat(subOrder?.numberOfUnits), 0)

		const type = order?.type
		const clientEmails = order?.clientEmails
		const clientCCEmails = order?.clientCCEmails
		const businessCode = order?.businessCode
		const orderNumber = parentOrder?.orderNumber
		const orderStatus = parentOrder?.status
		const doStatus = parentOrder?.DoStatuses?.[0]?.status
		// console.log(parentOrder)
		const refNumber = parentOrder?.preferences?.refNumber
		const preferences = parentOrder?.preferences
		const shippingLine = parentOrder?.shippingLine
		const documentNumber = parentOrder?.documentNumber
		const preparedOrderNumber = `I-${orderNumber}`
		const clearingAgents = parentOrder?.clearingAgents?.map((agent) => ({
			contactName: agent.name,
			contactPhone: agent.phoneNumber,
		}))
		const comments = parentOrder?.Comments
		const documents = [{ document: parentOrder?.document, text: 'BL Document' }]?.filter((document) => document?.document?.length > 0)
		// JOB + SUBORDER DETAIL
		const loadingDT = subImport?.loadingDT || ''

		const containerNumbers = subOrders?.length

		const biltyDTUnformatted =
			[...orderMovements]?.filter((movement) => movement?.Job)?.sort((a, b) => b?.Job?.bilty?.[0]?.biltyCreatedAt - a?.Job?.bilty?.[0]?.biltyCreatedAt)?.[0]
				?.Job?.bilty?.[0]?.biltyCreatedAt || ''
		const biltyDT =
			biltyDTUnformatted.length > 0
				? biltyDTUnformatted.length > 14
					? moment(biltyDTUnformatted).format('DD-MMM-YYYY')
					: moment(biltyDTUnformatted, 'x').format('DD-MMM-YYYY')
				: ''
		const biltyNumber = orderMovements?.map((movement) => movement?.Job?.bilty?.[0]?.biltyNumber)
		const driverVehicleRegistration = orderMovements?.map((movement) => movement?.Job?.Vehicle?.registrationNumber)
		const driverPhone = orderMovements?.map((movement) => movement?.Job?.Driver?.phoneNumber)
		const containerNumber = subOrders?.map((subOrder) => subOrder?.containerNumber)
		// ORDER LOCATIONS
		const orderLocations = subImport?.OrderLocations?.map((location) => ({
			...location,
			formattedAddress: location?.Location?.formattedAddress || 'none',
			additionalDetails: location?.Location?.additionalDetails || 'none',
		}))
		const loading = orderLocations?.filter((location) => location?.locationType === 'loading')
		const dropoff = orderLocations?.filter((location) => location?.locationType === 'dropoff')
		const releaseTerminal = orderLocations?.filter((location) => location?.locationType === 'Empty Container Dropoff')

		// DRIVER STATUS JOURNEY STATUSES
		const dropoffStatus = ['driverReachedDropoffLocation', 'driverMovingToDropoffLocation']
		const pickupStatus = ['driverMovingToPickupLocation', 'driverReachedPickupLocation']
		const containerStatus = ['driverMovingToContainerDropoffLocation', 'driverReachedContainerDropoffLocation']

		// ORDER CATEGORY
		const isSDOrder =
			parentOrder?.preferences?.movementType === 'ImportShifting' || parentOrder?.preferences?.movementType === 'ImportDoubleTwentyShifting'
		const isDoubleTwenty = parentOrder?.preferences?.movementType === 'DoubleTwenty'
		const orderCategory = isSDOrder ? (parentOrder?.preferences?.numberFlatbeds === subOrders.length ? 'shifting' : 'destuffing') : 'direct-seal'
		const numberOfVehicles = isDoubleTwenty
			? order?.VehicleInfos.reduce((acc, val) => (acc = acc + parseInt(val?.quantity)), 0)
			: orderCategory === 'direct-seal'
			? containerNumbers
			: preferences?.numberFlatbeds

		// MOVEMENTS + SUBORDER
		const movedCount = {}
		const movedUnits = orderMovements?.find((movement) => movement?.movedUnits)?.movedUnits
		orderMovements?.forEach((movement) => {
			const childOrder = movement?.ChildOrder?.SubImport
			movedCount[childOrder?._id] = (movedCount[childOrder?._id] || 0) + (movement?.status === 'PartialMatched' ? movement?.count : 0)
			movements[`${movement?.subMovementType}`] = {
				...movements[`${movement?.subMovementType}`],
				[`${movement?.Job?._id}`]: {
					_id: movement?.Job?._id || movement?._id,
					job: {
						...movement?.Job,
						dummy: movement?.Job?.Journeys?.map((journey) => journey?.DriverStatusHistories)
							?.flat()
							?.filter((history) => history?.deletedAt === null)
							?.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt))),
						driverStatusHistories: movement?.Job?.Journeys?.map((journey) => journey?.DriverStatusHistories)
							?.flat()
							?.reduce((prev, history) => {
								if (dropoffStatus.includes(history?.status)) {
									prev['dropoff'] = prev['dropoff'] ? [...prev['dropoff'], history] : [history]
								} else if (pickupStatus.includes(history?.status)) {
									prev['pickup'] = prev['pickup'] ? [...prev['pickup'], history] : [history]
								} else if (containerStatus.includes(history?.status)) {
									prev['container'] = prev['container'] ? [...prev['container'], history] : [history]
								}
								return prev
							}, {}),
						matched: movement?.status,
						type: movement?.movementType,
						subType: movement?.subMovementType,
					},
					subOrders: [
						...(movements?.[`${movement?.subMovementType}`]?.[`${movement?.Job?._id}`]?.subOrders || []),
						{
							_id: childOrder?._id,
							documentNumber: parentOrder?.documentNumber,
							orderNumber: parentOrder?.orderNumber,
							subOrderNumber: childOrder?.subOrderNumber,
							type: order?.type,
							freeDays: childOrder?.freeDays,
							doStatus: parentOrder?.DoStatuses?.[0]?.status,
							completeMatched: !movement?.partialMatch && !!movement?.totalCount,
							partialMatched: movement?.status === 'PartialMatched',
							movementCount: !movement?.partialMatch && !!movement?.totalCount ? movement?.totalCount : movement?.count,
							movedCount: movedCount[childOrder?._id],
							totalCount: movement?.totalCount,
							movedUnits: movement?.movedUnits,
							containerInfo: {
								containerNumber: childOrder?.containerNumber,
								dimension: childOrder?.dimension,
								freightType: childOrder?.freightType,
								freightWeight: childOrder?.freightWeight,
							},
							orderLocations: childOrder?.OrderLocations,
							preferences: parentOrder?.preferences,
							orderStatus: { status: childOrder?.status },
						},
					],
				},
			}
		})

		return {
			_id: order?._id,
			createdAt: order?.createdAt,
			emailThread: order?.emailThread,
			parentId: parentOrder?._id,
			freightType: subImport?.freightType,
			businessName: order?.Corporate?.businessName,
			freeDays: subImport?.freeDays,
			committedReturnDeadline: subImport?.committedReturnDeadline,
			totalWeight,
			totalNumberOfBales,
			clearingAgents,
			clientEmails,
			clientCCEmails,
			containerNumbers,
			containerSpecifics,
			orderLocations,
			businessCode,
			documentNumber,
			biltyNumber,
			driverVehicleRegistration,
			driverPhone,
			containerNumber,
			comments,
			releaseTerminal,
			documents,
			dropoff,
			doStatus,
			movedUnits,
			isSDOrder,
			loading,
			loadingDT,
			biltyDT,
			numberOfVehicles,
			orderCategory,
			orderNumber,
			orderStatus,
			preparedOrderNumber,
			refNumber,
			shippingLine,
			preferences,
			movements,
			type,
		}
	})
	return parentOrders
}

const getExportOrders = (orders) => {
	const parentOrders = orders?.map((order) => {
		const parentOrder = order?.ParentOrder?.ParentExport
		const orderMovements = order?.ParentOrder?.Movements
		const subExport = orderMovements?.[0]?.ChildOrder?.SubExport
		// console.log(subExport)
		let movements = {}
		const subOrders = Object.values(
			orderMovements?.reduce((subOrders, movement) => {
				subOrders[movement?.ChildOrder?.SubExport?._id] = movement?.ChildOrder?.SubExport
				return subOrders
			}, {})
		)

		const containerSpecifics = subOrders?.reduce((acc, subOrder) => {
			acc[subOrder?.dimension] = (acc[subOrder?.dimension] || 0) + 1
			return acc
		}, {})

		// PARENT ORDER DETAILS
		const type = order?.type
		const businessCode = order?.businessCode
		const clientEmails = order?.clientEmails
		const clientCCEmails = order?.clientCCEmails
		const orderNumber = parentOrder?.orderNumber
		const orderStatus = parentOrder?.status
		const refNumber = parentOrder?.preferences?.refNumber
		const preferences = parentOrder?.preferences
		const shippingLine = parentOrder?.shippingLine
		const documentNumber = parentOrder?.documentNumber
		const preparedOrderNumber = `E-${orderNumber}`
		const clearingAgents = parentOrder?.clearingAgents?.map((agent) => ({
			contactName: agent.name,
			contactPhone: agent.phoneNumber,
		}))
		const comments = parentOrder?.Comments
		const documents = [{ document: parentOrder?.document, text: 'CRO Document' }]?.filter((document) => document?.document?.length > 0)
		// JOB + SUBORDER DETAIL
		const loadingDT = subExport?.loadingDT || ''
		const containerNumbers = subOrders?.length
		const biltyDTUnformatted =
			[...orderMovements]?.filter((movement) => movement?.Job)?.sort((a, b) => b?.Job?.bilty?.[0]?.biltyCreatedAt - a?.Job?.bilty?.[0]?.biltyCreatedAt)?.[0]
				?.Job?.bilty?.[0]?.biltyCreatedAt || ''

		const biltyDT =
			biltyDTUnformatted.length > 0
				? biltyDTUnformatted.length > 14
					? moment(biltyDTUnformatted).format('DD-MMM-YYYY')
					: moment(biltyDTUnformatted, 'x').format('DD-MMM-YYYY')
				: ''
		// SEARCH PARAMS
		const biltyNumber = orderMovements?.map((movement) => movement?.Job?.bilty?.[0]?.biltyNumber)
		const driverVehicleRegistration = orderMovements?.map((movement) => movement?.Job?.Vehicle?.registrationNumber)
		const driverPhone = orderMovements?.map((movement) => movement?.Job?.Driver?.phoneNumber)
		const containerNumber = subOrders?.map((subOrder) => subOrder?.containerNumber)
		// ORDER LOCATIONS
		const orderLocations = subExport?.OrderLocations?.map((location) => ({
			...location,
			formattedAddress: location?.Location?.formattedAddress || 'none',
			additionalDetails: location?.Location?.additionalDetails || 'none',
		}))
		const loading = orderLocations?.filter((location) => location?.locationType === 'loading')
		const dropoff = orderLocations?.filter((location) => location?.locationType === 'dropoff')
		const releaseTerminal = orderLocations?.filter((location) => location?.locationType === 'Empty Container Pickup')
		// console.log({ releaseTerminal })

		const orderCategory = 'direct-seal'
		const numberOfVehicles = containerNumbers

		const dropoffStatus = ['driverReachedDropoffLocation', 'driverMovingToDropoffLocation']
		const pickupStatus = ['driverMovingToPickupLocation', 'driverReachedPickupLocation']
		const containerStatus = [
			'driverMovingToContainerDropoffLocation',
			'driverReachedContainerDropoffLocation',
			'driverReachedContainerTerminal',
			'driverMovingToContainerTerminal',
		]

		// MOVEMENTS + SUBORDER
		orderMovements?.forEach((movement) => {
			movements[`${movement?.subMovementType}`] = {
				...movements[`${movement?.subMovementType}`],
				[`${movement?.Job?._id}`]: {
					_id: movement?.Job?._id || movement?._id,
					job: {
						...movement?.Job,
						dummy: movement?.Job?.Journeys?.map((journey) => journey?.DriverStatusHistories)
							?.flat()
							?.filter((history) => history?.deletedAt === null)
							?.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt))),
						driverStatusHistories: movement?.Job?.Journeys?.map((journey) => journey?.DriverStatusHistories)
							?.flat()
							?.reduce((prev, history) => {
								if (dropoffStatus.includes(history?.status)) {
									prev['dropoff'] = prev['dropoff'] ? [...prev['dropoff'], history] : [history]
								} else if (pickupStatus.includes(history?.status)) {
									prev['pickup'] = prev['pickup'] ? [...prev['pickup'], history] : [history]
								} else if (containerStatus.includes(history?.status)) {
									prev['container'] = prev['container'] ? [...prev['container'], history] : [history]
								}
								return prev
							}, {}),
						matched: movement?.status,
						type: movement?.movementType,
						subType: movement?.subMovementType,
					},
					subOrders: [
						...(movements?.[`${movement?.subMovementType}`]?.[`${movement?.Job?._id}`]?.subOrders || []),
						prepareImportExportSubOrder(movement?.ChildOrder?.SubExport, parentOrder, order, orderCategory),
					],
				},
			}
		})

		return {
			_id: order?._id,
			createdAt: order?.createdAt,
			emailThread: order?.emailThread,
			parentId: parentOrder?._id,
			freightType: subExport?.freightType,
			businessName: order?.Corporate?.businessName,
			clientCommittedDeliveryDate: subExport?.clientCommittedDeliveryDate,
			vesselCutoffDT: parentOrder?.vesselCutoffDT,
			clearingAgents,
			containerNumbers,
			clientEmails,
			clientCCEmails,
			documentNumber,
			biltyNumber,
			containerSpecifics,
			driverVehicleRegistration,
			driverPhone,
			containerNumber,
			orderCategory,
			numberOfVehicles,
			comments,
			businessCode,
			documents,
			dropoff,
			loading,
			releaseTerminal,
			loadingDT,
			biltyDT,
			orderLocations,
			orderNumber,
			orderStatus,
			preparedOrderNumber,
			refNumber,
			shippingLine,
			preferences,
			movements,
			type,
		}
	})
	return parentOrders
}

const getLongHaulOrders = (orders) => {
	const parentOrders = orders?.map((order) => {
		const parentOrder = order?.ParentOrder?.ParentLongHaul
		const orderMovements = order?.ParentOrder?.Movements
		const subLongHaul = orderMovements?.[0]?.ChildOrder?.SubLongHaul
		let movements = {}
		const subOrders = Object.values(
			orderMovements?.reduce((subOrders, movement) => {
				subOrders[movement?.ChildOrder?.SubLongHaul?._id] = movement?.ChildOrder?.SubLongHaul
				return subOrders
			}, {})
		)
		// PARENT ORDER DETAILS
		const type = order?.type
		const businessCode = order?.businessCode
		const clientEmails = order?.clientEmails
		const clientCCEmails = order?.clientCCEmails
		const orderNumber = parentOrder?.orderNumber
		const orderStatus = parentOrder?.status
		const preferences = parentOrder?.preferences
		const refNumber = parentOrder?.preferences?.refNumber
		const isLotOrder = preferences?.lotOrder

		const preparedOrderNumber = `L-${orderNumber}`
		const comments = parentOrder?.Comments
		const documents = [{ document: parentOrder?.document, text: 'CRO Document' }]?.filter((document) => document?.document?.length > 0)
		// JOB + SUBORDER DETAIL
		const loadingDT = subLongHaul?.loadingDT || ''
		const biltyDTUnformatted =
			[...orderMovements]?.filter((movement) => movement?.Job)?.sort((a, b) => b?.Job?.bilty?.[0]?.biltyCreatedAt - a?.Job?.bilty?.[0]?.biltyCreatedAt)?.[0]
				?.Job?.bilty?.[0]?.biltyCreatedAt || ''
		const biltyDT =
			biltyDTUnformatted.length > 0
				? biltyDTUnformatted.length > 14
					? moment(biltyDTUnformatted).format('DD-MMM-YYYY')
					: moment(biltyDTUnformatted, 'x').format('DD-MMM-YYYY')
				: ''
		// SEARCH PARAMS
		const biltyNumber = orderMovements?.map((movement) => movement?.Job?.bilty?.[0]?.biltyNumber)
		const driverVehicleRegistration = orderMovements?.map((movement) => movement?.Job?.Vehicle?.registrationNumber)
		const driverPhone = orderMovements?.map((movement) => movement?.Job?.Driver?.phoneNumber)

		// ORDER CATEGORY
		const orderCategory = isLotOrder ? 'lot' : 'non-lot'
		const numberOfVehicles = preferences?.numberVehicles

		// ORDER LOCATIONS
		const orderLocations = subLongHaul?.OrderLocations?.map((location) => ({
			...location,
			formattedAddress: location?.Location?.formattedAddress || 'none',
			additionalDetails: location?.Location?.additionalDetails || 'none',
		}))
		const loading = orderLocations?.filter((location) => location?.locationType === 'loading')
		const dropoff = orderLocations?.filter((location) => location?.locationType === 'dropoff')

		const dropoffStatus = ['driverReachedDropoffLocation', 'driverMovingToDropoffLocation']
		const pickupStatus = ['driverMovingToPickupLocation', 'driverReachedPickupLocation']
		const containerStatus = ['driverMovingToContainerDropoffLocation', 'driverReachedContainerDropoffLocation']

		orderMovements?.forEach((movement) => {
			movements[`${movement?.subMovementType}`] = {
				...movements[`${movement?.subMovementType}`],
				[`${movement?.Job?._id}`]: {
					_id: movement?.Job?._id || movement?._id,
					job: {
						...movement?.Job,
						dummy: movement?.Job?.Journeys?.map((journey) => journey?.DriverStatusHistories)
							?.flat()
							?.filter((history) => history?.deletedAt === null)
							?.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt))),
						driverStatusHistories: movement?.Job?.Journeys?.map((journey) => journey?.DriverStatusHistories)
							?.flat()
							?.reduce((prev, history) => {
								if (dropoffStatus.includes(history?.status)) {
									prev['dropoff'] = prev['dropoff'] ? [...prev['dropoff'], history] : [history]
								} else if (pickupStatus.includes(history?.status)) {
									prev['pickup'] = prev['pickup'] ? [...prev['pickup'], history] : [history]
								} else if (containerStatus.includes(history?.status)) {
									prev['container'] = prev['container'] ? [...prev['container'], history] : [history]
								}
								return prev
							}, {}),
						matched: movement?.status,
						type: movement?.movementType,
						subType: movement?.subMovementType,
					},
					subOrders: [
						...(movements?.[`${movement?.subMovementType}`]?.[`${movement?.Job?._id}`]?.subOrders || []),
						prepareLongHaulSubOrder(movement?.ChildOrder?.SubLongHaul, parentOrder, order),
					],
				},
			}
		})

		return {
			_id: parentOrder?._id,
			parentId: parentOrder?._id,
			createdAt: order?.createdAt,
			emailThread: order?.emailThread,
			freightType: subLongHaul?.shipmentInfo?.freightType,
			businessName: order?.Corporate?.businessName,
			shipmentInfo: parentOrder?.shipmentInfo,
			clientEmails,
			clientCCEmails,
			documents,
			comments,
			dropoff,
			isLotOrder,
			biltyNumber,
			driverVehicleRegistration,
			driverPhone,
			businessCode,
			loading,
			loadingDT,
			biltyDT,
			orderLocations,
			orderCategory,
			numberOfVehicles,
			orderNumber,
			orderStatus,
			preparedOrderNumber,
			refNumber,
			preferences,
			movements,
			type,
		}
	})
	return parentOrders
}

const prepareImportExportSubOrder = (subOrder, parentOrder, order, orderCategory) => ({
	_id: subOrder?._id,
	documentNumber: parentOrder?.documentNumber,
	orderNumber: parentOrder?.orderNumber,
	subOrderNumber: subOrder?.subOrderNumber,
	type: order?.type,
	freeDays: subOrder?.freeDays,
	clientCommittedDeliveryDate: subOrder?.clientCommittedDeliveryDate,
	doStatus: subOrder?.DoStatuses?.[0]?.status,
	containerInfo: {
		containerNumber: subOrder?.containerNumber,
		dimension: subOrder?.dimension,
		freightType: subOrder?.freightType,
		freightWeight: subOrder?.freightWeight,
	},
	orderLocations: subOrder?.OrderLocations,
	preferences: parentOrder?.preferences,
	orderStatus: { status: subOrder?.status },
})

const prepareLongHaulSubOrder = (subOrder, parentOrder, order) => ({
	_id: subOrder?._id,
	shipmentInfo: subOrder?.shipmentInfo,
	orderNumber: parentOrder?.orderNumber,
	subOrderNumber: subOrder?.subOrderNumber,
	type: order?.type,
	vehicleInfo: {
		vehicleType: subOrder?.vehicleType,
		vehicleSubtype: subOrder?.vehicleSubType,
	},
	orderLocations: subOrder?.OrderLocations,
	preferences: parentOrder?.preferences,
	orderStatus: { status: subOrder?.status },
})
